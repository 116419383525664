.ImgPlusButtons {
  grid-area: img;

  .Img {
    display: block;
    width: 100%;
    height: 670px;
    border-radius: 8px;
  }

  .Buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 8px;

    .SocialButtons {
      display: flex;
      align-items: center;

      .LikeActive {
        fill: #EF3340;

        path {
          stroke: #EF3340;
        }
      }

      svg {
        margin-left: 24px;
        cursor: pointer;

        &:last-child {
          margin-left: 30px;
        }

        &:hover {
          &:nth-of-type(1) {
            path {
              stroke: #EF3340;
            }
          }

          &:nth-of-type(2) {
            path {
              fill: #EF3340;
            }
          }

          &:nth-of-type(3) {
            path {
              fill: #EF3340;
            }
          }

          &:nth-of-type(4) {
            path {
              stroke: #EF3340;
            }
          }
        }
      }

      span {
        font-family: Roboto, sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 160%;
        color: #303141;
        margin-left: 6px;
      }
    }

    .CopyButton {
      min-width: 167px;
    }
  }
}