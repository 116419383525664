@import "../../../../../submodules/naoo-web-components/styles/variables";
@import "../../../../../submodules/naoo-web-components/styles/mixins";

.Buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;

  @include media-breakpoint-down(sm) {
    height: 40px;
    margin-top: 0;
  }

  .SocialButtons {
    display: flex;
    align-items: center;

    .LikeActive {
      fill: var(--brand-01);

      path {
        stroke: var(--brand-01);
      }
    }

    svg {
      margin-left: 24px;
      cursor: pointer;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-left: 30px;
      }

      &:hover {
        &:nth-of-type(1) {
          path {
            stroke: var(--brand-01);
          }
        }

        &:nth-of-type(2) {
          path {
            fill: var(--brand-01);
          }
        }

        &:nth-of-type(3) {
          path {
            fill: var(--brand-01);
          }
        }

        &:nth-of-type(4) {
          path {
            stroke: var(--brand-01);
          }
        }

      }

    }

    span {
      font-family: Roboto, sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 160%;
      color: #303141;
      margin-left: 6px;
    }
  }

  .CopyButton {
    min-width: 167px;

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }
}