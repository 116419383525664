@import "src/submodules/naoo-web-components/styles/variables";
@import "src/submodules/naoo-web-components/styles/mixins";


.Container {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.Title{
  display: inline-block;
  @include text-hero;
  color: var(--text-01);
  margin-right: 40px;
}

.SubTitle{
  display: inline-block;
  @include text-20-medium;
  color: var(--text-01);
  margin-right: 40px;
}
