.Container{
    position: relative;
    width: 317px;
    min-height: 276px;
    box-shadow: 0 15px 15px rgba(48, 49, 65, 0.05);
    border-radius: 6px;
}

.Text{
    margin: 40px 16px 8px 16px;
    /*padding-top: 10px;*/
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #303141;
    border-radius: 6px;

}
.Text strong{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.BottomContainer{
    position: absolute;
    left: 0;
    bottom: 0;
    width: 317px;
    min-height: 128px;
    background-size: contain;
}


.InfoContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #303141;
}

.Logo{
    position: absolute;
    left: calc(50% - 64px/2);
    top: calc(50% - 64px/2);
    width: 64px;
    height: 64px;
    border-radius: 50%;
    z-index: 3;
}
.Cover{
    width: 317px;
    height: 178px;
    border-radius: 6px;
}
.Dot{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #C1C1C6;
    margin: 0 4px;
}
.NaooIcon{
    margin-left: 5px;
}


.FirstLetter {
    position: absolute;
    left: calc(50% - 64px/2);
    top: calc(50% - 64px/2);
    display: block;
    height: 64px;
    width: 64px;
    background: rgba(48, 49, 65, 0.05);
    line-height: 64px;
    text-align: center;
    border-radius: 50%;
    font-family: Jost;
    font-style: normal;
    font-weight: bold;
    z-index: 3;
}
