@import "../../../../submodules/naoo-web-components/styles/variables";
@import "../../../../submodules/naoo-web-components/styles/mixins";

.Container {
  display: grid;
  grid-template-rows: 76px auto 56px;
  grid-template-columns: 50%  50%;
  grid-template-areas: "img header"
                        "img comments"
                        "img addComments";
  width: 1096px;
  height: 726px;
  background-color: #fff;
  box-shadow: 0 15px 15px rgba(48, 49, 65, 0.05);
  border-radius: 8px;
  margin: auto;

  @include media-breakpoint-down(sm) {
    position: relative;
    display: grid;
    grid-template-columns: auto !important;
    grid-template-rows: auto !important;
    grid-template-areas: "header"
                          "img"
                          "comments"
                          "addComments" !important;
    width: 100vw;
    height: initial;
    box-shadow: 0px -14px 14px rgba(0, 0, 0, 0.04);
    border-radius: 32px;
    overflow: visible;
  }
}