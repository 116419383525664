@import "src/submodules/naoo-web-components/styles/variables";
@import "src/submodules/naoo-web-components/styles/mixins";

.Container {
  display: grid;
  grid-template-rows: auto;
  align-items: center;
  @include text-14-reg;
  color: var(--text-01);
  padding: 16px 24px;
  border-bottom: 1px solid var(--selection);

  &:last-child {
    border: none;
  }

  @include media-breakpoint-down(sm) {
    padding-left: 0;
    padding-right: 0;
    justify-items: center;
    grid-row-gap: 12px;
  }
}