@import "src/submodules/naoo-web-components/styles/variables";
@import "src/submodules/naoo-web-components/styles/mixins";

.Container {
  display: flex;

  .Main {
    width: 100%;
    padding-left: 24px;
    padding-right: 24px;

    @include media-breakpoint-down(sm) {
      padding-left: 12px;
      padding-right: 12px;
    }

    .SubscriptionsContainer {
      display: flex;
      flex-direction: column;
      max-width: 1024px;
      justify-content: space-around;
      padding-top: 80px;
      padding-bottom: 48px;
    }

    .Cards {
      display: flex;
      justify-content: space-between;
    }

    .Unsubscribe {
      padding-top: 16px;
      align-self: end;
    }
  }

}
