@import "../../../../../submodules/naoo-web-components/styles/variables";
@import "../../../../../submodules/naoo-web-components/styles/mixins";

.Comment {
  display: flex;
  width: 100%;
  margin-top: 11px;
  @include media-breakpoint-down(sm) {
    padding: 0 12px;
  }

  .Logo {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    margin-right: 12px;
  }

  .UserNameAndInfo {
    display: flex;
    align-items: center;
    margin-bottom: 3px;
    @include text-14-reg-jost;
    color: var(--text-02);

    .Name {
      margin-right: 12px;
      @include text-14-bold;
      color: var(--text-01);
    }

    .Time {
      margin-right: 5px;
    }

    .AuthorName {
      margin-left: 5px;
    }

    .Like {
      margin-left: auto;

      svg {
        cursor: pointer;

        &:hover {
          path {
            stroke: var(--brand-01);
          }
        }
      }

      .LikeActive {
        fill: var(--brand-01);

        path {
          stroke: var(--brand-01);
        }
      }
    }
  }

  .Text {
    width: 100%;
    @include text-14-reg;
    color: var(--text-01);
  }

  .Reply {
    display: flex;
    align-items: center;
    margin-top: 4px;
    @include text-14-reg;
    color: var(--text-02);

    svg {
      cursor: pointer;
      margin-left: 10px;

      rect {
        fill: var(--text-02)
      }

      &:hover {
        rect {
          fill: var(--brand-01)
        }

        path {
          fill: var(--brand-01)
        }
      }
    }
  }
}