@import "src/submodules/naoo-web-components/styles/variables";
@import "src/submodules/naoo-web-components/styles/mixins";

.NaooBusinessLogo {
  display: block;
  margin: 10% auto 40px auto;

  @include media-breakpoint-down(sm) {
    margin: 20px 32px 0;
  }
}

.FormContainer {
  position: relative;
  max-width: 906px;
  margin: 0 auto;
  padding: 56px 40px 43px;
  background: var(--white);
  box-shadow: 0 15px 15px rgba(48, 49, 65, 0.05);
  border-radius: 6px;

  @include media-breakpoint-down(sm) {
    position: static;
    margin: 35% 0 0;
    padding: 0 32px 0;
    box-shadow: none;
  }

  .Times {
    position: absolute;
    right: 20px;
    top: 20px;

    &:hover {
      background-color: #e3e1e1;
      border-radius: 50%;
      cursor: pointer;
    }

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  .CloseIcon {
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;

    @include media-breakpoint-down(sm) {
      display: none;
    }

    rect {
      fill: #303141;
    }

    &:hover {
      rect {
        fill: #EF3340;
      }
    }
  }

  .OfferImageDropzone {
    width: 200px;
    height: 200px;
    border-radius: 50%;
  }

  .CoverDropzoneSize {
    width: 356px;
    height: 200px;
    @include media-breakpoint-down(sm) {
      width: 100%;
      height: 175px;
    }
  }

}

.BusinessForm {
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  background: var(--white);
  border-radius: 6px;
}

.FormTitle {
  @include text-hero;
  color: var(--text-01);

  @include media-breakpoint-down(sm) {
    @include text-24-bold;
  }
}

.Label {
  @include text-14-reg;
  color: var(--text-01);
}

.Subtitle {
  margin: 24px 0 0;
  @include text-16-reg;
  color: var(--text-01);
}

.GroupTitle {
  margin: 0 0 24px 0;
  @include text-20-bold;
  color: var(--text-01);
}

.CategoryContainer {
  position: relative;
  width: 100%;
}

.InputContainer {
  margin-bottom: 32px;
  @include media-breakpoint-down(sm) {
    margin-bottom: 24px;
  }
}

.TextWithButton {
  display: flex;
  align-items: center;
  margin-top: 24px;

  @include media-breakpoint-down(sm) {
    width: 100%;
    flex-direction: column;
  }

  .TextContainer {
    display: flex;
    align-items: flex-start;

    .Text {
      display: block;
      max-width: 547px;
      margin-right: 130px;
      margin-left: 10px;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 20px;

      @include media-breakpoint-down(sm) {
        margin-right: 0;
        @include text-14-reg;
      }
    }
  }
}

.Button {

  @include media-breakpoint-down(sm) {
    width: 100%;
    margin-top: 32px;
  }
}

.BackButton {
  @include media-breakpoint-down(sm) {
    position: absolute;
    left: 32px;
    top: 50px;
  }
}

.ButtonsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.DropzoneContainer {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 45px;
}

.PendingMediaInfo {
  width: 120px;
  margin-left: 25px;
}

.MapContainer {
  width: 100%;
  height: 300px;
  margin-bottom: 60px;
  @include media-breakpoint-down(sm) {
    margin-bottom: 32px;
  }
}


.GalleryContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 32px;
  @include media-breakpoint-down(sm) {
    display: block;
  }

  .Gallery {
    gap: 9px;

    .GalleryDropzone {
      grid-column: 1 / span 4;
      width: 335px;
      height: 200px;
    }

    .GalleryItem {
      width: 200px;
      height: 200px;
    }
  }
}

.CheckboxContainer {
  display: flex;
  align-items: center;
  margin-top: -8px;
  margin-bottom: 24px;
  width: fit-content;
  cursor: pointer;
}

.CheckboxLabel {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #303141;
  margin-left: 12px;
}