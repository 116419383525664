@import "src/submodules/naoo-web-components/styles/variables";
@import "src/submodules/naoo-web-components/styles/mixins";
@import "src/submodules/naoo-web-components/styles/colors";

*{
  box-sizing: border-box;
}
body {
  margin: 0;
  padding: 0;
  top: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color:var(--bg);
  box-sizing: border-box;
  @include media-breakpoint-down(sm) {
    background-color: var(--white);
  }
}

img{
  object-fit: cover;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* полоса прокрутки (скроллбар) */
::-webkit-scrollbar {
  width: 6px; /* ширина для вертикального скролла */
  height: 8px; /* высота для горизонтального скролла */
  background-color: transparent;
}

/* ползунок скроллбара */
::-webkit-scrollbar-thumb {
  background-color: var(--text-03);
  border-radius: 10px;
  min-height: 56px;
}

::-webkit-scrollbar-corner{
  display: none;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #253861;
}

/* Стрелки */

::-webkit-scrollbar-button:vertical:start:decrement {
  display: none;
}

::-webkit-scrollbar-button:vertical:end:increment {
  display: none;
}

::-webkit-scrollbar-button:horizontal:start:decrement {
  display: none;
}

::-webkit-scrollbar-button:horizontal:end:increment {
 display: none;
}

