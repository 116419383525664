@import "src/submodules/naoo-web-components/styles/variables";
@import "src/submodules/naoo-web-components/styles/mixins";

.Container {
  margin-top: 24px;
  max-width: 912px;

  .PostPageHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .Button {
      @include text-16-bold-jost;
      color: var(--complementary-02);
    }
  }

  .NoPostsContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 195px;
    margin-top: 24px;
    background-color: var(--white);
    box-shadow: 0 15px 15px rgba(48, 49, 65, 0.05);
    border-radius: 6px;

    p {
      @include text-16-reg;
      text-align: center;
      color: var(--text-01);

      span {
        color: var(--complementary-02);
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  .Header {
    grid-template-columns: 34% 3% 18% 3% 14% 3% 8% 3% auto;
    grid-template-areas:
      "post . originAccount . publishDate . comments . likes";

    .Post {
      grid-area: post;
    }

    .OriginAccount {
      grid-area: originAccount;
    }

    .PublishDate {
      grid-area: publishDate;
    }

    .Comments {
      grid-area: comments;
    }

    .Likes {
      grid-area: likes;
    }
  }

  .PostItem {
    grid-template-columns: 34% 3% 18% 3% 14% 3% 8% 3% 4% auto;
    grid-template-areas:
      "post . account . publishDate . comments . likes. more";
    cursor: pointer;

    &:hover {
      background-color: var(--bg);
    }

    @include media-breakpoint-down(sm) {
      grid-template-columns: 1fr 3% 1fr;
      grid-template-areas:
      "account . publishDate"
      "post post post";
      grid-row-gap: 8px;
    }

    .Post {
      grid-area: post;
      display: flex;
      align-items: center;
      @include text-14-bold;
      color: var(--new);

      @include media-breakpoint-down(sm) {
        width: 100%;
        flex-direction: row-reverse;
        justify-content: space-between;
      }

      .Logo {
        width: 45px;
        height: 56px;
        border-radius: 8px;
        object-fit: cover;
        object-position: center;
      }
    }

    .Account {
      grid-area: account;
      color: var(--text-01);
      @include media-breakpoint-down(sm) {
        justify-self: start;
      }
    }

    .PublishDate {
      grid-area: publishDate;
      color: var(--new);

      .Scheduled{
        margin-right: 4px;
      }

      @include media-breakpoint-down(sm) {
        justify-self: end;
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        .Scheduled{
          background-color: var(--selection);
          padding: 2px 8px;
          border-radius: 4px;
          margin-right: 0;
        }
      }
    }

    .Comments {
      grid-area: comments;
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }

    .Likes {
      grid-area: likes;
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }

    .More {
      grid-area: more;
      justify-self: flex-end;
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }
  }
}