@import "src/submodules/naoo-web-components/styles/variables";
@import "src/submodules/naoo-web-components/styles/mixins";

.Container{
  margin-top: 40px;
  margin-bottom: 40px;
  background-color: var(--white);
  padding: 0;
  border-radius: 8px;

  @include media-breakpoint-down(sm) {
    margin-top: 0;
    padding: 0;
    background-color: transparent;
  }
}