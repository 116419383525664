@import "src/submodules/naoo-web-components/styles/variables";
@import "src/submodules/naoo-web-components/styles/mixins";

.Container {
  margin-top: 24px;
  max-width: 912px;

  .Header {
    grid-template-columns:  24% 3% 16% 3% 16% 3% 16% 3% 16%;
    grid-template-areas:
      "Username . Comments . Shares . Mentions . Likes";
  }

  .FollowerItem {
    grid-template-columns:  24% 3% 16% 3% 16% 3% 16% 3% 16%;
    grid-template-areas:
      "Username . Comments . Shares . Mentions . Likes";

    @include media-breakpoint-down(sm) {
      grid-template-columns:  minmax(58px, 1fr) minmax(5px, 2%) minmax(76px, 1fr) minmax(5px, 2%) minmax(92px, 1fr) minmax(5px, 2%) minmax(66px, 1fr);
      grid-template-areas:
        "Username Username Username Username Username Username Username"
        "Comments . Shares . Mentions . Likes";
    }

    .Title {
      display: none;
      margin-right: 6px;
      @include text-14-reg;
      color: var(--text-02);
      @include media-breakpoint-down(sm) {
        display: inline-block;
      }
    }

    .Logo {
      img {
        width: 32px;
        height: 32px;
      }

      margin-right: 12px;
    }

    .Username {
      grid-area: Username;
      display: flex;
      align-items: center;
      @include media-breakpoint-down(sm) {
        justify-self: start;
      }
    }
  }
}

.Username {
  grid-area: Username;
}

.Comments {
  grid-area: Comments;
}

.Shares {
  grid-area: Shares;
}

.Mentions {
  grid-area: Mentions;
}

.Likes {
  grid-area: Likes;
}