.Separator {
  position: relative;
  display: block;
  top: 23px;
  left: 0px;
  width: 18px;
  height: 2px;
  background-color: #c4c4c4;
}

.Highlighted {
  color: #ef3340;
}
