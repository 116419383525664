@import "src/submodules/naoo-web-components/styles/variables";
@import "src/submodules/naoo-web-components/styles/mixins";

.OfferPreview {
  max-width: 320px;
  height: fit-content;
  display: block;
  background: var(--white);
  border-radius: 8px;
  padding-bottom: 16px;

  @include media-breakpoint-down(sm) {
    margin-top: 24px;
    justify-self: center;
  }
}

.OfferPreviewHeader {
  display: flex;
  padding-left: 20px;
  padding-right: 20px;
}

.OfferPreviewImageContainer {
  position: relative;
  width: 320px;
  height: 396px;
  margin-bottom: 8px;
}

.ImagePlaceholder {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border: 1px dashed var(--text-03);
  border-radius: 6px;
}

.OfferImage {
  width: 320px;
  height: 396px;
  border-radius: 6px;
}

.TitleAndText {
  min-height: 120px;
}

.OfferTitle {
  word-wrap: break-word;
  margin: 8px 16px 2px;
  @include text-16-bold;
  color: var(--text-01);
}

.OfferTitle .Discount {
  margin-right: 6px;
  color: var(--brand-01);
}

.OfferDescription {
  margin: 8px 16px 2px;
  word-wrap: break-word;
  @include text-16-reg;
  color: var(--text-01);
}


.Number {
  margin-left: 7px;
  @include text-16-bold;
  color: var(--text-01);
}

.CoinsContainer {
  position: absolute;
  left: 10px;
  bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  padding: 0 10px 0 12px;
  background: var(--white);
  border-radius: 6px;
}

.CoinsText {
  margin-right: 5px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
}

.Logo {
  position: absolute;
  right: 10px;
  bottom: 10px;
}

.OfferPreview .Logo > img {
  width: 50px;
  height: 50px;
  border-radius: 6px;
}

.TimeAndDistance {
  margin: 0 16px;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  color: var(--black);
}

.OfferTime {
  margin-right: 4px;
}

.LocationIcon path {
  fill: var(--brand-01);
}

.OfferLocation {
  margin-left: 4px;
}

.Button {
  margin-top: 12px;
}

.Button button {
  margin: 0 16px;
  width: 285px;
  text-transform: none;
}

.Button span {
  text-transform: none;
}

.Button span.Price {
  margin-right: 8px;
  opacity: 0.64;
}

.Button span.Discount {
  margin-right: 8px;
}

.Button span.Dot {
  margin-right: 8px;
}


