.Title {
  position: relative;
  display: block;
  top: 14px;
  width: 48px;

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  text-align: center;
  color: #303141;
}

.Number {
  position: relative;
  top: 10px;

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;

  display: block;
  align-items: center;
  text-align: center;

  color: #303141;
}

.Box {
  position: relative;
  width: 48px;
  height: 48px;
  background: #ffffff;
  border-radius: 6px;
  border: 0px;
}

.Highlighted {
  color: #ef3340;
}
