.List{
  margin-top: 48px;
  margin-bottom: 28px;
  padding: 0;
  list-style-position: inside;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.Info{
  margin-top: 24px;
  margin-bottom: 48px;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #303141;
}

.FileContainer{
  position: relative;
  .Spinner{
    background-color: var(--dropzone-bg-color);
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    width: 24px;
    height: 24px;
  }
}



.Message{
  .Link{
    color: #FFFFFF;
  }
}