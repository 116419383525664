@import "../../../../submodules/naoo-web-components/styles/variables";
@import "../../../../submodules/naoo-web-components/styles/mixins";

.Post{
  @include text-14-bold;
  color: var(--new);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}
.MediaContainer{
  width: 45px;
  height: 56px;
  margin-right: 16px;
  @include media-breakpoint-down(sm) {
    margin-right: 0;
  }
}

.Repost{
 @include text-14-reg;
  color: var(--text-01);
  margin-top: 4px;
  padding-left: 8px;
  border-left: 2px solid var(--brand-01);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.NotPublished{
 @include text-14-reg;
  color: var(--text-01);
  margin-bottom: 4px;
}
.Delayed{
  opacity: 0.55;
}