@import "src/submodules/naoo-web-components/styles/variables";
@import "src/submodules/naoo-web-components/styles/mixins";

.Container {
  display: flex;

  .Main {
    width: 100%;
    padding-left: 24px;
    padding-right: 24px;

    @include media-breakpoint-down(sm) {
      padding-left: 12px;
      padding-right: 12px;
    }
  }
}