
.Container {
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2px #EF3340 solid;
  border-radius: 10px;
  padding: 16px;
  min-height: 300px;

  h2 {
    font-size: 38px;
    padding: 16px;
    margin: 0;
  }

  h3 {
    padding: 8px;
    margin: 0;
  }

  h4 {
    padding: 0;
    margin: 0;
    font-weight: normal;
    min-height: 16px;
  }

  ul {
    padding: 16px;
    width: 100%;
    font-size: 14px;
    min-height: 200px;

    li {
      padding: 4px;
    }

    span:first-child {
      font-weight: bold;
    }
  }

  .Unsubscribe {
    padding-top: 16px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: end;
  }

}
