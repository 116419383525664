@import "../../../../../submodules/naoo-web-components/styles/variables";
@import "../../../../../submodules/naoo-web-components/styles/mixins";

.AddCommentContainer {
  grid-area: addComments;
  display: flex;
  align-items: center;
  border-top: 1px solid #E3E6F7;
  padding: 0 20px 0 24px;

  @include media-breakpoint-down(sm) {
    align-self: end;
    margin: 12px;
    padding: 8px 12px;
    border: 1px solid var(--ui-02);
    background-color: var(--bg);
    border-radius: 12px;
    height: 52px;
  }

  .Logo {
    width: 36px;
    height: 36px;
    margin-right: 12px;
    border-radius: 50%;
    flex-shrink: 0;
  }

  .Input {
    width: 100%;
    @include text-14-reg;
    color: var(--text-01);
    height: 32px;
    border: none;
    outline: none;

    @include media-breakpoint-down(sm) {
      background-color: var(--bg);
    }

    &::placeholder {
      color: var(--text-02);
    }
  }

  .PostButton {
    margin-left: auto;
    @include text-14-bold;
    text-align: right;
    color: var(--complementary-02);
    background-color: var(--white);
    border: none;
    cursor: pointer;

    &:hover {
      color: var(--brand-01)
    }
  }
}