.Container{
  padding-left: 16px;
  padding-right: 16px;
  background-color: var(--dropzone-bg-color);
  border: var(--dropzone-border);
  border-radius: 12px;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;

  p{
    display: flex;
    align-items: center;
    justify-content: center;

    .Or{
      margin-left: 8px;
      margin-right: 8px;
    }

    .Link{
      text-decoration-line: underline;
      color: #3C49C6;
    }
  }

  .FilesContainer{
    display: flex;
    flex-direction: column;

    .File{
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 14px;
      margin-bottom: 14px;
    }
    .ClearIcon{
      rect {
        fill: #8B8B8B;
      }

      &:hover{
        rect {
          fill: #EF3340;
        }
      }
    }
  }
}

.DragActive{
  border: var(--dropzone-hover-bg-color);
}