@import "src/submodules/naoo-web-components/styles/variables";
@import "src/submodules/naoo-web-components/styles/mixins";

.Dropdown {
  width: 200px;
  height: auto;
  z-index: 999;
  background: var(--white);
  box-shadow: 0 15px 15px rgba(48, 49, 65, 0.05);
  border-radius: 6px;

  ul {
    position: relative;
    list-style: none;
    padding: 0;

    li {
      display: flex;
      align-items: center;
      padding: 8px;
      cursor: pointer;

      &:hover {
        background-color: var(--selection)
      }
    }
  }
}

.BusinessName {
  @include jost;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 16px;
  color: var(--text-01);
}

.BusinessSubscription {
  margin-left: 16px;
  font-size: 12px;
}

.BusinessLogo {
  width: 40px;
  height: 40px;
  filter: drop-shadow(0px 15px 15px rgba(48, 49, 65, 0.05));
  border-radius: 50%;
  margin-right: 8px;
}

.AddBusinessButtonContainer {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 8px;

  &:hover {
    background-color: var(--selection);
  }

  img {
    margin-left: 17px;
    margin-right: 10px;
  }

  span {
    @include text-14-medium-jost;
    letter-spacing: 0.0075em;
    color: var(--complementary-02);
  }
}