@import "src/submodules/naoo-web-components/styles/variables";
@import "src/submodules/naoo-web-components/styles/mixins";

.Topbar {
  height: 72px;
}

.SelectedBusinessContainer {
  display: flex;
  align-items: center;
  color: var(--text-01);
  margin-top: 16px;
  @include jost;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  cursor: pointer;

  .selectedBusinessLogo {
    img {
      width: 40px;
      height: 40px;
    }
  }
}

.SelectedBusinessContainerDisabled {
  pointer-events: none;
  color: #c4c4c4;
}


.selectedBusinessName {
  font-weight: 700;
  margin-left: 16px;
}

.selectedBusinessAddress {
  margin-left: 8px;
}

.selectedBusinessSubscription {
  margin-left: 8px;
}