.CreateOfferStepsOverview {
  height: 80px;

  display: inline-block;
  margin-top: 19px;
  margin-bottom: 32px;
}

.CreateOfferStepsOverview ul {
  margin: 0px;
  display: flex;
  list-style-type: none;
  padding: 0px;
}

.CreateOfferStepsOverview li {
  padding-right: 20px;
}
