.Container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;

  .Field {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
  }
}

.Buttons {
  padding-top: 16px;
  display: flex;
  justify-content: end;
  column-gap: 16px;
}
