.Comment{
  display: flex;
  width: 100%;
  margin-top: 11px;
  .Logo{
    width: 36px;
    height: 36px;
    border-radius: 50%;
    margin-right: 12px;
  }
  .UserNameAndInfo{
    display: flex;
    align-items: center;
    width: 97%;
    margin-bottom: 3px;
    font-family: Jost,sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 14px;
    color: #9798A0;
    .Name{
      margin-right: 12px;
      font-family: Roboto, sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 18px;
      color: #303141;
    }
    .Time{
      margin-right: 5px;
    }
    .AuthorName{
      margin-left: 5px;
    }
    .Like{
      margin-left: auto;
      svg{
        cursor: pointer;
        &:hover{
          path{
            stroke:#EF3340;
          }
        }
      }
      .LikeActive{
        fill:#EF3340;
        path{
          stroke: #EF3340;
        }
      }
    }
  }
  .Text{
    width: 100%;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #303141;

  }
  .Reply{
    display: flex;
    align-items: center;
    margin-top: 4px;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #9798A0;

    svg{
      cursor: pointer;
      margin-left: 10px;
      rect{
        fill: #9798A0
      }
      &:hover{
        rect{
          fill: #EF3340
        }
        path{
          fill: #EF3340
        }
      }
    }
  }
}