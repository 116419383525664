@import "src/submodules/naoo-web-components/styles/variables";
@import "src/submodules/naoo-web-components/styles/mixins";

.Container {
  display: grid;
  grid-template-rows: auto;
  align-items: center;
  @include text-14-reg;
  color: var(--text-02);
  padding: 24px 24px 16px;
  border-bottom: 1px solid var(--selection);

  @include media-breakpoint-down(sm) {
    display: none;
  }
}