@import "../../../../../submodules/naoo-web-components/styles/variables";
@import "../../../../../submodules/naoo-web-components/styles/mixins";

.Header {
  grid-area: header;
  display: flex;
  align-items: center;
  padding: 16px 20px 12px 24px;

  @include media-breakpoint-down(sm) {
    padding: 34px 12px 6px;
  }

  .More {
    margin-left: auto;

    svg {
      cursor: pointer;

      &:hover {
        path {
          fill: #EF3340;
        }
      }
    }
  }
}

.Comments {
  grid-area: comments;
  overflow-y: auto;
  padding: 0 20px 0 24px;

  @include media-breakpoint-down(sm) {
    overflow-y: visible;
    padding: 0 12px;
  }

  .PostText {
    @include text-16-reg;
    color: var(--text-01);
  }

  .ViewCommentsButton {
    @include text-14-reg;
    color: var(--text-02);
    cursor: pointer;

    &:hover {
      color: var(--brand-01)
    }
  }
}