@import "src/submodules/naoo-web-components/styles/variables";
@import "src/submodules/naoo-web-components/styles/mixins";

.EditOfferContainer {
  margin-top: 24px;
  max-width: 1200px;
  width: 100%;
}

.Title {
  display: block;
  margin-top: 16px;
  margin-bottom: 40px;
  @include text-hero;
  color: var(--text-01);
}

.ComponentAndPreview {
  display: grid;
  grid-template-columns: 1fr 320px;
  grid-column-gap: 32px;

  @include media-breakpoint-down(sm) {
    grid-template-columns: 1fr;
  }
}

.ButtonsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 60px;
}
