@import "src/submodules/naoo-web-components/styles/variables";
@import "src/submodules/naoo-web-components/styles/mixins";

.Header {
  grid-template-columns: 41% 3% 9% 3% 53px 3% minmax(52px, 5%) 3% minmax(79px, 9%) 3% 9%;
  grid-template-areas:
      "Name . Impressions . Walkins . Reach . Engagement . Active";

  .Name {
    grid-area: Name;
  }

  .Impressions {
    grid-area: Impressions;
  }

  .Walkins {
    grid-area: Walkins;
  }

  .Reach {
    grid-area: Reach;
  }

  .Engagement {
    grid-area: Engagement;
  }

  .Active {
    grid-area: Active;
  }

  @include media-breakpoint-down(md) {
    display: none;
  }
}

.Item {
  grid-template-columns: 40px 16px calc(41% - 56px) 3% 9% 3% 53px 3% minmax(52px, 5%) 3% minmax(79px, 9%) 3% 9% 3% auto;
  grid-template-areas:
      "Logo . Name . Impressions . Walkins . Reach . Engagement . Active . More";


  &:hover {
    background-color: var(--bg);
    cursor: pointer;
  }

  @include media-breakpoint-down(md) {
    grid-template-columns: 44px 16px 1fr 24px 1fr;
    grid-template-areas:
      "Logo . Name Name Name"
      "Logo  . Reach . Impressions"
      "Logo  . Walkins . Engagement";
    padding-left: 0;
    padding-right: 0;
    justify-items: start;
    grid-row-gap: 4px;
  }

  .Logo {
    grid-area: Logo;
    width: 40px;
    height: 40px;
    border-radius: 8px;
    @include media-breakpoint-down(md) {
      align-self: start;
      width: 44px;
      height: 44px;
    }
  }

  .Label{
    display: none;
    @include text-12-reg;
    color: var(--text-02);
    @include media-breakpoint-down(md) {
      display: inline-block;
      margin-right: 4px;
    }
  }
  .LabelSmall {
    //width: 46px;
  }

  .Name {
    grid-area: Name;
    display: flex;
    flex-direction: column;
    margin-bottom: 4px;
  }

  .Impressions {
    grid-area: Impressions;
    justify-self: end;
    @include media-breakpoint-down(md) {
      justify-self: start;
    }
  }

  .Walkins {
    grid-area: Walkins;
    justify-self: end;
     @include media-breakpoint-down(md) {
       justify-self: start;
     }
  }

  .Reach {
    grid-area: Reach;
  }

  .Active {
    grid-area: Active;
     @include media-breakpoint-down(md) {
       display: none;
     }
  }

  .Engagement {
    grid-area: Engagement;
    justify-self: end;
     @include media-breakpoint-down(md) {
       justify-self: start;
     }
  }

  .More {
    grid-area: More;
     @include media-breakpoint-down(md) {
       display: none;
     }
  }
}

.IsActiveButton {
  width: 76px;
  height: 40px;
  padding: 0;
  margin: 0;
  border: 0;
  vertical-align: middle;
  background: transparent;
}

.Inactive {
  opacity: 0.55;

  span {
    &:first-child {
      font-weight: normal;
    }
  }
}