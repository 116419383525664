@import "src/submodules/naoo-web-components/styles/variables";
@import "src/submodules/naoo-web-components/styles/mixins";

.Container {
  position: relative;
  display: inline-block;
  @include text-16-medium;
  color: var(--text-01);

  .TimeframeText {
    cursor: pointer;
  }
}

.ItemsContainer {
  box-shadow: 0 15px 15px rgba(48, 49, 65, 0.05);
  background-color: var(--white);
  min-width: 150px;
  border-radius: 8px;
  @include text-14-bold;

  .List {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  .List li {
    padding: 11px 16px;
    cursor: pointer;

    &:hover {
      color: var(--brand-01)
    }
  }
}