.Logo{
  margin-top: 32px;
  margin-bottom: 48px;
  vertical-align: top;
  display: block;
}

.SidebarOffset {
  height: 50px;
}

.SidebarOffset {
  height: 50px;
}
