@import "src/submodules/naoo-web-components/styles/variables";
@import "src/submodules/naoo-web-components/styles/mixins";

.Container {
  max-width: 1100px;
  width: 100%;
  padding-top: 32px;

  .TitleContainer {
    margin-top: 16px;
    margin-bottom: 24px;
  }

  .FormContainer {
    display: grid;
    grid-template-columns: 1fr 317px;
    grid-column-gap: 32px;

    @include media-breakpoint-down(sm) {
      grid-template-columns: 1fr;
    }

    .Preview {
      @include media-breakpoint-down(sm) {
        justify-self: center;
      }
    }

    .PreviewContainer {
      width: 317px;
      @include media-breakpoint-down(sm) {
        width: 100%;
      }
    }

    .Section {
      padding: 32px 24px;
      background: var(--white);
      border-radius: 8px;

      .Title {
        margin-bottom: 24px;
        @include text-20-bold;
        color: var(--text-01);
      }

      .Input {
        &::placeholder {
          color: #C1C1C6;
        }
      }

      .LabelMargin {
        margin-top: 24px;
        margin-bottom: 8px;
      }

      .Label {
        @include text-14-reg;
        color: var(--text-01);
      }

      .Buttons {
        display: flex;
        align-items: center;
        margin-top: 24px;

        .Left {
          height: 40px;
          border-bottom-left-radius: 8px;
          border-top-left-radius: 8px;
        }

        .Right {
          height: 40px;
          border-bottom-right-radius: 8px;
          border-top-right-radius: 8px;
        }

        .Subtitle {
          margin-right: 40px;
          @include text-14-reg;
          color: var(--text-01);
        }
      }

      .Schedule {
        margin-top: 24px;

        .ScheduleInput {
          background-color: var(--input-bg-color);

          &::placeholder {
            color: #C1C1C6;
          }
        }
      }

      .QrCodeContainer {
        display: flex;
        align-items: center;

        .Qr {
          width: fit-content;
          border-radius: 4px;
          overflow: hidden;
        }

        .Print {
          margin-left: 8px;
          @include text-14-reg;
          color: #3C49C6;
        }
      }

      .GalleryContainer {
        margin-top: 24px;

        .Gallery {
          grid-template-columns: repeat(2, 1fr);
          gap: 13px;
        }

        .ItemStyle {
          width: 128px;
          height: 128px;

          &:nth-child(2) {
            grid-column: 1 / span 2;
            width: 100%;
            height: 336px;
          }
        }

        .GalleryDropzoneWithMedia {
          width: 128px;
          height: 128px;
          order: 2;
        }

        .GalleryDropzoneWithoutMedia {
          width: 100%;
          grid-column: 1 / span 2;
        }
      }
    }

    .Section + .Section {
      margin-top: 24px;
    }

    .SaveButtonContainer {
      display: flex;
      justify-content: flex-end;
      margin-top: 40px;
    }

    .ChannelsContainer {
      .Channel {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        width: fit-content;
        cursor: pointer;

        label {
          margin-right: 12px;
        }

        .Title {
          font-family: Roboto, sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
          color: #303141;
        }
      }
    }

  }
}