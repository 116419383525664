@import "src/submodules/naoo-web-components/styles/variables";
@import "src/submodules/naoo-web-components/styles/mixins";

.Button {
  width: 100%;
}

.Text {
  display: block;
  margin: 32px auto;
  text-align: center;
  @include text-16-reg;
  color: var(--text-01);
}

.TextButton {
  color: var(--complementary-02);
  cursor: pointer;
}

.TabContainer {
  display: flex;
  margin-bottom: 24px;
}

.Tab input {
  display: none;
}

.Tab input + span {
  display: block;
  width: 139px;
  padding-bottom: 4px;
  text-align: center;
  cursor: pointer;
}

.Tab input:checked + span {
  border-bottom: 4px solid var(--complementary-02);
}

.CheckBoxContainer {
  display: flex;
  align-items: flex-start;
  margin-bottom: 32px;
}

.CheckBoxContainer a {
  text-decoration: none;
}

.Label {
  margin-left: 12px;
  @include text-14-reg;
  color: var(--text-01);
  cursor: pointer;
}

.InputContainer {
  position: relative;
  margin-bottom: 24px;
}

.PasswordEyeIcon {
  position: absolute;
  right: 20px;
  top: 40px;
  cursor: pointer;
}

.PasswordInfoContainer {
  display: flex;
  align-items: center;
  margin-bottom: 32px;
  @include text-14-reg;
  color: var(--text-01);
}

.PasswordInfoContainer img {
  margin-right: 8px;
}

.ErrorsContainer {
  position: fixed;
  width: 325px;
  left: 50%;
  transform: translate(-50%);
  bottom: 32px;
}