.Container {
  position: relative;
  width: 906px;
  padding: 56px 40px;
  background-color: #fff;
  box-shadow: 0 15px 15px rgba(48, 49, 65, 0.05);
  border-radius: 6px;
  margin: auto;

  .ModalTitle {
    margin-bottom: 16px;
    font-family: Jost, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 38px;
    line-height: 40px;
    color: #303141;
  }

  .ModalText {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #303141;
  }

  .ButtonsContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 48px;
  }
}