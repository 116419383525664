@import "../../../../../submodules/naoo-web-components/styles/variables";
@import "../../../../../submodules/naoo-web-components/styles/mixins";

.ImgPlusButtons {
  grid-area: img;

  .Img {
    display: block;
    width: 100%;
    height: 670px;
    border-radius: 8px;
    overflow: hidden;

    @include media-breakpoint-down(sm) {
      height: 469px;
    }

    :global {
      .swiper-slide {
        width: 100% !important;
      }
    }
  }

  .ButtonsContainer {
    padding-left: 24px;

    @include media-breakpoint-down(sm) {
      padding-left: 12px;
    }
  }
}