@import "src/submodules/naoo-web-components/styles/variables";
@import "src/submodules/naoo-web-components/styles/mixins";

.OffersHeader{
  display: flex;
  align-items: center;
  padding: 24px 24px 0;
  @include media-breakpoint-down(md) {
    padding: 24px 0 0;
  }
}

.OffersSectionTitle {
  @include text-20-bold;
  color: var(--text-01);
  margin-right: 24px;
  @include media-breakpoint-down(md) {
    display: none;
  }
}

.OffersSectionContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 32px;
}

.CheckBox {
  margin-right: 12px;
  cursor: pointer;
}

.NoOffersIcon {
  display: block;
  height: 41px;
  width: 64px;
}

.NoOffersText {
  text-align: center;
  @include text-16-reg;
  color: var(--black);
}

.CreateFirstOfferBtn {
  width: 212px;
  height: 46px;
  background: #fdebec;
  border-radius: 100px;
  border: 0;
  @include text-16-medium;
  color: var(--brand-01);
  cursor: pointer;
  outline: none;
}

.ShowOnlyActiveOffersCheckbox {
  display: inline-block;
  width: 24px;
  height: 24px;
  cursor: pointer;
  margin-left: 20px;
}

.ShowOnlyActiveOffersLabel {
  display: inline-block;
  @include text-14-reg;
  color: var(--text-01);
  cursor: pointer;
}

.CreateNewOfferContainer {
  display: inline;
}

.CreateNewOfferButton {
  display: inline;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  width: 25px;
  height: 25px;
  padding: 0;
  margin-left: auto;
  margin-right: 10px;

  @include media-breakpoint-down(md) {
    display: none;
  }
}

.CreateNewOfferLabel {
  display: inline;
  cursor: pointer;
  @include text-16-medium;
  color: var(--complementary-02);
  @include media-breakpoint-down(md) {
    display: none;
  }
}

.CreateNewOfferButtonFixed{
  position: fixed;
  left: 50%;
  bottom: 34px;
  transform: translateX(-50%);
  display: none;
  @include media-breakpoint-down(md) {
    display: block;
  }
}