.PostedBy{
  display: flex;
  align-items: center;

  .Logo{
    margin-right: 8px;
  }

  .Text{
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;

    .Title{
      color: #303141;
    }

    .User{
      color: #9798A0;
    }
  }
}